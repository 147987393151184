import React from "react"
import getConfig, { EnvironmentConfiguration } from "./config/env"
import ApiService from "./modules/app/api/ApiService"
import AuthStore from "./modules/app/model/AuthStore"
import UserNotificationStore from "./modules/app/model/UserNotificationStore"

export default class AppServiceManager {
  environmentConfig: EnvironmentConfiguration

  apiService: ApiService

  authStore: AuthStore
  userNotificationStore: UserNotificationStore

  constructor(environmentConfig: EnvironmentConfiguration) {
    this.environmentConfig = environmentConfig
    //common
    this.userNotificationStore = new UserNotificationStore()
    this.authStore = new AuthStore(this.environmentConfig.keycloak)

    this.apiService = new ApiService(
      this.authStore,
      this.environmentConfig.apiURL
    )
  }

  async init() {
    await this.initAllServices()
  }

  async initAllServices() {
    this.authStore.init()
  }
}

export const ServiceManagerContext = React.createContext<
  AppServiceManager | undefined
>(undefined)

const serviceManager = new AppServiceManager(getConfig())
serviceManager.init()

export const ServiceManagerInstance = serviceManager
