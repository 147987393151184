import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import "./PhotoSelection.scss"
import classNames from "classnames"
import Button, { ButtonColor } from "../Button/Button"
import { MdThumbUp, MdThumbDown, MdRotateRight, MdImage } from "react-icons/md"
import { observer } from "mobx-react-lite"
import PhotoStore, { IPhoto } from "../../model/PhotoStore"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { ServiceManagerInstance } from "../../../../AppServiceManager"
import { ImagePlaceholder, LazyImage } from "../Image/Image"
import { ToastMessageColor } from "../../model/UserNotificationStore"
import Select from "react-select"
import { MdCheck } from "react-icons/md"
import { Range } from "../Range/Range"
import { isEqual } from "lodash"

export const NO_OP = () => {
  /**/
}

const DEFAULT_SIGNATURE_IMAGE_RATIO = 1.3333333

interface IActionFooterProps {
  selectedCount: number
  visible?: boolean
  onPhotoDeletionClick: (reason: string) => void
  onPhotoRetainClick: (reason: string) => void
}

const ActionFooter = ({
  selectedCount = 0,
  visible = true,
  onPhotoDeletionClick,
  onPhotoRetainClick,
}: IActionFooterProps) => {
  interface IConfirmationState {
    visible: boolean
    confirmationText?: string
    buttonText?: string
    action?: (reason: string) => void
    reasons: { value: string | null; label: string }[]
  }

  const [confirmation, setConfirmation] = useState<IConfirmationState>({
    visible: false,
    reasons: [],
  })

  const noOption = {
    value: null,
    label: "Grund auswählen",
  }

  const retainOptions = [
    {
      value: "notSuspicious",
      label: "notSuspicious",
    },
  ]

  const deleteOptions = [
    noOption,
    {
      value: "personErkennbar",
      label: "Person zu erkennen",
    },
    {
      value: "ausweisdokumentErkennbar",
      label: "Ausweisdokument zu erkennen",
    },
    {
      value: "tattoo",
      label: "Tattoo",
    },
    {
      value: "anderes",
      label: "anderer Grund",
    },
  ]

  const [selectedOption, setSelectedOption] = useState<{
    value: string | null
    label: string
  }>(deleteOptions[0])

  const resetConfirmation = () => {
    setConfirmation({
      visible: false,
      reasons: [],
    })
    setSelectedOption(noOption)
  }

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      resetConfirmation()
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)

    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [])

  interface ICustomOptionProps {
    data: {
      value: any
      label: string
    }
    innerProps: unknown
    innerRef: any
    isSelected: boolean
  }

  const CustomSelectOption = ({
    data,
    innerProps,
    innerRef,
    isSelected,
  }: ICustomOptionProps) => (
    <div
      className={classNames("input-select", "CustomSelectOption")}
      ref={innerRef}
      {...innerProps}
    >
      <div
        className={classNames(
          "input-select__single-value",
          "CustomSelectOption-SingleValue"
        )}
      >
        {isSelected && <MdCheck className={"CustomSelectOption-Icon"} />}
        <span className={"CustomSelectOption-Label"}>{data.label}</span>
      </div>
    </div>
  )

  return (
    <div className={classNames("ActionFooter", { hidden: !visible })}>
      {!confirmation.visible ? (
        <>
          <span className="SelectedItemCount">
            {selectedCount}
            {selectedCount === 1 ? " Foto " : " Fotos "} ausgewählt
          </span>
          <div className="ButtonGroup">
            <Button
              className="ActionFooter-Button"
              text="Löschen"
              icon={MdThumbDown}
              color={ButtonColor.Danger}
              onClick={(e) => {
                e.preventDefault()
                setConfirmation({
                  visible: true,
                  confirmationText:
                    "Die ausgewählten Fotos werden endgültig gelöscht",
                  action: onPhotoDeletionClick,
                  reasons: deleteOptions,
                })
              }}
            />
            <Button
              className="ActionFooter-Button"
              text="Behalten"
              icon={MdThumbUp}
              color={ButtonColor.Success}
              onClick={(e) => {
                e.preventDefault()
                setConfirmation({
                  visible: true,
                  confirmationText:
                    "Für die ausgewählten Fotos wurde kein Datenschutzverstoß festgestellt. Sie werden daher wieder in allen Systemen angezeigt.",
                  action: onPhotoRetainClick,
                  reasons: retainOptions,
                })
                setSelectedOption(retainOptions[0])
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div
            className="ActionFooter-Overlay"
            onClick={() => {
              resetConfirmation()
            }}
          />
          <div className="SelectedItemCount">
            <span style={{ display: "block" }}>
              {confirmation.confirmationText}
            </span>
            {confirmation.reasons.length > 1 && (
              <div
                style={{
                  width: "210px",
                  marginLeft: "50px",
                }}
              >
                <Select
                  value={selectedOption}
                  components={{
                    IndicatorSeparator: () => null,
                    Option: CustomSelectOption,
                  }}
                  styles={{
                    menu: (styles) => ({
                      ...styles,
                      minWidth: "320px",
                    }),
                    control: (styles) => ({
                      ...styles,
                      borderColor: "#BEBEBE",
                      "&:hover": {
                        borderColor: "#01B5FF",
                        boxShadow: "0 0 0 1px #01B5FF",
                      },
                      boxShadow: "none",
                    }),
                  }}
                  onChange={(change) => {
                    const { value, label } = change as {
                      value: string | null
                      label: string
                    }
                    change && setSelectedOption({ value, label })
                  }}
                  menuPlacement={"top"}
                  options={deleteOptions.filter((o) => o.value !== null)}
                  autoSize={true}
                />
              </div>
            )}
          </div>
          <div className="ButtonGroup">
            <Button
              disabled={selectedOption.value === null}
              className="ActionFooter-Button"
              text={confirmation.buttonText || "Bestätigen"}
              color={ButtonColor.Primary}
              onClick={(e) => {
                e.preventDefault()
                if (!confirmation.action) {
                  throw new Error("Action must be set in previous step")
                }
                if (!selectedOption.value) {
                  throw new Error("Reason must be set")
                }
                confirmation.action(selectedOption.label)
                resetConfirmation()
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}

export interface ISelectAllProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
}

const SelectAll = ({ onChange, checked = false }: ISelectAllProps) => {
  return (
    <div className="SelectAll">
      <label>
        <input
          id="SelectAll-Checkbox"
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(e)}
        />
        &nbsp; Alle auswählen
      </label>
    </div>
  )
}

export interface IPhotoPopupProps {
  rotation?: number
  displayDirection?: {
    leftRight: "left" | "right"
    topBottom: "top" | "bottom"
  }
  width?: number
  xOffset?: number
  imageUrl: string
}

const PhotoPopup = ({
  rotation = 0,
  displayDirection = {
    leftRight: "left",
    topBottom: "top",
  },
  width = 500,
  xOffset = 50,
  imageUrl,
}: IPhotoPopupProps) => {
  const style: React.CSSProperties =
    displayDirection?.leftRight === "right"
      ? {
          right: -width - xOffset,
        }
      : {
          left: -width - xOffset,
        }

  if (displayDirection?.topBottom === "top") {
    style.top = "-25%"
  } else {
    style.transform = "translateY(-80%)"
  }

  return (
    <div style={{ ...{ width }, ...style }} className={classNames("Popup")}>
      <img
        alt={""}
        src={imageUrl}
        className={"Popup-Image"}
        style={{ transform: `rotate(${rotation}deg)` }}
      />
    </div>
  )
}

export interface IPhotoHoverOverlayProps {
  onRotateLeft: () => void
  onRotateRight: () => void
  style?: React.CSSProperties
}

export const PhotoHoverOverlay = ({
  onRotateRight,
}: IPhotoHoverOverlayProps) => {
  return (
    <div className={"PhotoHoverOverlay"}>
      <div className={"RotationWrapper"}>
        <div className={"PhotoHoverOverlay-IconWrapper"}>
          <MdRotateRight
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onRotateRight()
            }}
          />
        </div>
      </div>
    </div>
  )
}

const imageStyles = {
  0: {},
  90: {
    transform: `rotate(90deg) translateY(-100%)`,
    transformOrigin: "top left",
  },
  180: {
    transform: `rotate(180deg) translate(-100%, -100%)`,
    transformOrigin: "top left",
  },
  270: {
    transform: `rotate(270deg) translateX(-100%)`,
    transformOrigin: "top left",
  },
}

type Degree = keyof typeof imageStyles

export interface IPhotoItemProps {
  onSelect: () => void
  onDeselect: () => void
  url: string
  selected: boolean
  photo: IPhoto
  initialRotation: Degree
}

const PhotoItem = observer(
  ({
    onSelect,
    onDeselect,
    url,
    selected,
    initialRotation = 90,
  }: IPhotoItemProps) => {
    const [hovering, setHovering] = useState(false)
    const [rotation, setRotation] = useState<Degree>(0)
    const [imageSrcBlob, setImageSrcBlob] = useState(ImagePlaceholder)
    const [imageStyle, setImageStyle] = useState({})
    const store = useContext(PhotoStore)

    const rotateRight = () => {
      setRotation(((rotation + 90) % 360) as Degree)
    }

    const rotateLeft = () => {
      setRotation(((rotation - 90) % 360) as Degree)
    }

    const wrapperRef = useRef<HTMLDivElement>(null)

    const recalculateImageStyle = useCallback(() => {
      if (!wrapperRef) {
        setImageStyle({})
        return
      }

      // Depending on the rotation we translate the origin of the image
      // We cannot transform from center because this can cause a breakout
      // of the image container
      const transformationStyle = (() => {
        return imageStyles[initialRotation]
      })()

      // If we rotate 90 or 270 degree, we swap the width and height
      // to make the image fit it's container.
      const widthHeightStyle = (() => {
        if (!wrapperRef.current) {
          return {}
        }

        if ([270, 90].includes(initialRotation)) {
          return {
            maxWidth: wrapperRef.current.getBoundingClientRect().height,
            maxHeight: wrapperRef.current.getBoundingClientRect().width,
          }
        } else {
          return {
            maxWidth: wrapperRef.current.getBoundingClientRect().width,
            maxHeight: wrapperRef.current.getBoundingClientRect().height,
          }
        }
      })()

      const newStyle = {
        ...transformationStyle,
        ...widthHeightStyle,
      }
      setImageStyle((v) => {
        if (isEqual(v, newStyle)) {
          return v
        }
        return newStyle
      })
    }, [initialRotation, wrapperRef])

    useEffect(() => {
      recalculateImageStyle()
    })

    const figureRef = useRef<HTMLElement>(null)
    const { width, height } = useWindowDimensions()
    const popupWidth = 500
    const popupHeight = 400
    const popupOffset = 50

    const getFigurePositionX = () => {
      if (!figureRef || !figureRef.current) {
        return 0
      }
      const { x, width } = figureRef.current.getBoundingClientRect()
      return x + width
    }

    const getFigurePositionY = () => {
      if (!figureRef || !figureRef.current) {
        return 0
      }
      const { y } = figureRef.current.getBoundingClientRect()
      return y
    }

    const fitScreenHorizontal = (currentPoint: number) =>
      width > currentPoint + popupOffset + popupWidth

    const fitScreenVertical = (currentPoint: number) => {
      return height > currentPoint + popupOffset + popupHeight
    }

    const imageWidth = store.gallerySize
    const imageRatio = DEFAULT_SIGNATURE_IMAGE_RATIO
    const imageInPortrait = [0, 180].includes(initialRotation)
    const imageHeight = imageWidth / imageRatio

    const signatureImageStyle = {
      width: !imageInPortrait ? imageWidth : imageHeight,
      height: !imageInPortrait ? imageHeight : imageWidth,
    }

    return (
      <div
        className={classNames("PhotoItem", {
          selected,
        })}
        style={signatureImageStyle}
        ref={wrapperRef}
        onMouseOver={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onClick={() => {
          if (selected) {
            onDeselect()
          } else {
            onSelect()
          }
        }}
      >
        <figure
          // onMouseOver={() => setHovering(true)}
          // onMouseLeave={() => setHovering(false)}
          ref={figureRef}
        >
          <LazyImage
            style={imageStyle}
            selected={selected}
            onLoadCompleted={setImageSrcBlob}
            loadFn={async () => {
              return store.fetchPhoto(url).then((blob) => {
                return URL.createObjectURL(blob)
              })
            }}
          />
        </figure>
        {hovering && (
          <PhotoHoverOverlay
            onRotateLeft={rotateLeft}
            onRotateRight={rotateRight}
          />
        )}
        {hovering && (
          <PhotoPopup
            rotation={rotation}
            displayDirection={{
              leftRight: fitScreenHorizontal(getFigurePositionX())
                ? "right"
                : "left",
              topBottom: fitScreenVertical(getFigurePositionY())
                ? "top"
                : "bottom",
            }}
            imageUrl={imageSrcBlob}
          />
        )}
      </div>
    )
  }
)

PhotoItem.displayName = "PhotoItem"

interface IPhotoSelectionHeaderProps {
  totalCount: number
}

const PhotoSelectionHeader = observer(
  ({ totalCount }: IPhotoSelectionHeaderProps) => {
    const store = useContext(PhotoStore)

    const { gallerySize } = store

    return (
      <div className="PhotoSelectionHeader">
        <span className="Text">{totalCount} Fotos zur Überprüfung</span>
        <SelectAll
          onChange={(e) => {
            if (e.target.checked) {
              store.selectAll()
            } else {
              store.deselectAll()
            }
          }}
          checked={store.allSelected}
        />
        <div className="Slider">
          <span className={"ImageIcon"}>
            <MdImage />
          </span>
          <Range
            min={175}
            max={700}
            step={25}
            onChange={(size) => store.setGalleryImageSize(size)}
            value={gallerySize}
          />
        </div>
      </div>
    )
  }
)

const PhotoSelection = observer(() => {
  const store = useContext(PhotoStore)
  const [error, setError] = useState<Response>()

  useEffect(() => {
    store.fetchPhotos().catch(setError)
  }, [])

  if (error && error.status === 403) {
    throw new Error(
      "Sie verfügen nicht über die Berechtigung Photo Control aufzurufen"
    )
  } else if (error) {
    throw error
  }

  return (
    <div className={"PhotoSelection-Wrapper"}>
      {error}
      <div className="PhotoSelection">
        <PhotoSelectionHeader totalCount={store.remainingPhotos} />

        <div
          className="PhotoList"
          style={{
            gridTemplateColumns: `repeat(auto-fill, ${store.gallerySize}px)`,
          }}
        >
          {store.photos.map((photo) => (
            <PhotoItem
              key={photo.id}
              url={photo.url}
              photo={photo}
              onSelect={() => store.selectPhoto(photo)}
              onDeselect={() => store.deselectPhoto(photo)}
              selected={photo.selected}
              initialRotation={90}
            />
          ))}
        </div>
        <ActionFooter
          selectedCount={store.selectedPhotoCount}
          visible={store.selectedPhotoCount > 0}
          onPhotoDeletionClick={(reason) => {
            store
              .deleteSelectedPhotos(reason)
              .then((deletedElements) => {
                ServiceManagerInstance.userNotificationStore.addToastMessage(
                  `Bilder gelöscht`,
                  `${deletedElements.length} ${
                    deletedElements.length > 1
                      ? "Unterschriftenbilder"
                      : "Unterschriftenbild"
                  } ${
                    deletedElements.length > 1 ? "wurden" : "wurde"
                  } unwiderruflich gelöscht!`
                )
              })
              .catch((ex) => {
                ServiceManagerInstance.userNotificationStore.addToastMessage(
                  `Fehler bei API`,
                  `Bilder konnten nicht gelöscht werden. Statuscode: ${ex.status}`,
                  ToastMessageColor.Danger
                )
              })
          }}
          onPhotoRetainClick={(reason) => {
            store
              .retainSelectedPhotos(reason)
              .then((retainedPhotos) => {
                ServiceManagerInstance.userNotificationStore.addToastMessage(
                  `Bilder wiederhergestellt`,
                  `${retainedPhotos.length} ${
                    retainedPhotos.length > 1
                      ? "Unterschriftenbilder"
                      : "Unterschriftenbild"
                  } ${
                    retainedPhotos.length > 1 ? "werden" : "wird"
                  }  wieder in in allen System angezeigt.`
                )
              })
              .catch((ex) => {
                ServiceManagerInstance.userNotificationStore.addToastMessage(
                  `Fehler bei API`,
                  `Bilder konnten nicht wiederhergestellt werden. Statuscode: ${ex.status}`,
                  ToastMessageColor.Danger
                )
              })
          }}
        />
      </div>
    </div>
  )
})

export default PhotoSelection
