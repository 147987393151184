import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"

export interface IImageProps {
  src?: string
  loadFn?: () => Promise<string>
  selected: boolean
  alt?: string
  style: React.CSSProperties
}

export const Image = React.forwardRef<HTMLImageElement, IImageProps>(
  ({ src, loadFn, alt, style = {} }: IImageProps, ref) => {
    const [imageSrc, setImageSrc] = useState<string>()
    const [error, setError] = useState(null)
    if (src && src !== imageSrc) {
      setImageSrc(src)
    }
    if (loadFn) {
      loadFn().then(setImageSrc).catch(setError)
    }

    return (
      <>
        {error ? (
          <span>Bild konnte nicht geladen werden: {error}</span>
        ) : (
          <img
            className={classNames("PhotoItem-Image")}
            src={imageSrc}
            style={style}
            alt={alt}
            ref={ref}
          />
        )}
      </>
    )
  }
)

Image.displayName = "Image"

export const ImagePlaceholder =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII="

export interface ILazyImageProps extends IImageProps {
  onLoadCompleted: (src: string) => void
}

export const LazyImage = ({
  selected,
  onLoadCompleted,
  loadFn,
  style = {},
}: ILazyImageProps) => {
  const [imageSrc, setImageSrc] = useState(ImagePlaceholder)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    let observer: IntersectionObserver
    let didCancel = false

    if (imageRef && imageSrc === ImagePlaceholder) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              // when image is visible in the viewport + rootMargin
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                if (loadFn) {
                  loadFn().then((src) => {
                    setImageSrc(src)
                    onLoadCompleted && onLoadCompleted(src)
                  })
                } else {
                  setImageSrc(imageSrc)
                  onLoadCompleted && onLoadCompleted(imageSrc)
                }
              }
            })
          },
          {
            threshold: 0.01,
            rootMargin: "75%",
          }
        )
        observer.observe(imageRef.current as Element)
      } else {
        // Old browsers fallback
        if (loadFn) {
          loadFn().then(setImageSrc)
        } else {
          setImageSrc(imageSrc)
        }
      }
    }
    return () => {
      didCancel = true
      // on component unmount, we remove the listener
      if (observer && observer.unobserve && imageRef) {
        observer.unobserve(imageRef.current as Element)
      }
    }
  })

  return (
    <Image style={style} ref={imageRef} src={imageSrc} selected={selected} />
  )
}
