import moment from "moment"
import timeoutPromise from "../../utils/timeoutPromise"
import AuthStore from "../model/AuthStore"
import { HttpHeaders } from "./HttpHeaders"
import HttpRequestTypes, { HttpRequestType } from "./HttpRequestType"
import { RequestStatistics } from "./RequestStatistics"
import RequestUtil from "./RequestUtil"

export type NoResponse = {
  ok: boolean
  fetchRejection: boolean
  error: string
}

export default class ApiService {
  authStore: AuthStore

  apiBase: string

  constructor(authStore: AuthStore, apiBase: string) {
    this.authStore = authStore
    this.apiBase = apiBase
  }

  async request(
    path: string,
    method: HttpRequestType = HttpRequestTypes.GET,
    headers?: {
      [key: string]: string | undefined
    },
    body?: unknown,
    timeout = 10000,
    additionalRequestConfig?: Partial<Request>
  ): Promise<Response> {
    this.authStore.onStartedRequest()

    if (!this.authStore.authToken) {
      this.authStore.onUnauthorized()
    }
    let url = this.getApiBase() + path
    if (path.match(/(http:\/\/.+)|(https:\/\/)/)) {
      url = path
    }
    const requestStats: RequestStatistics = {
      success: false,
      firedAt: moment(),
      executionCount: 1,
    }

    const customHeaders: { [key: string]: string } = {}
    if (headers)
      for (const header of Object.getOwnPropertyNames(headers)) {
        const val = headers[header]
        if (!!val) customHeaders[header] = val
      }

    let encodedBody: string | undefined
    if (method === HttpRequestTypes.GET) {
      if (method === HttpRequestTypes.GET && body instanceof Object)
        url = RequestUtil.appendParametersToUrl(url, body)
    } else {
      if (typeof body !== "string") encodedBody = JSON.stringify(body)
    }

    let response: Response
    try {
      let request: Promise<Response> = fetch(url, {
        headers: {
          ...this._getDefaultHeader(),
          ...customHeaders,
        },
        method,
        body: encodedBody,
        ...additionalRequestConfig,
      })
      if (timeout > 0) {
        request = timeoutPromise(timeout, request)
      }
      response = await request
    } catch (e) {
      requestStats.success = false
      requestStats.responseCode = e.status
      requestStats.durationMs = moment().diff(
        requestStats.firedAt,
        "milliseconds"
      )
      const failedResult = {
        ok: false,
        fetchRejection: true,
        error: e,
      }
      throw failedResult
    }
    requestStats.success = response.ok
    requestStats.responseCode = response.status
    requestStats.durationMs = moment().diff(
      requestStats.firedAt,
      "milliseconds"
    )
    if (response?.ok) {
      this.authStore.onSuccessfulRequest()
      return response
    } else {
      if (response.status === 401) {
        this.authStore.onUnauthorized()
      }

      this.authStore.onUnsuccessfulRequest(requestStats)
      throw response
    }
  }

  /**
   * Private method to get the API base url from the app settings.
   * @return {string}
   * @private
   */
  getApiBase(): string {
    return this.apiBase + "/"
  }

  /**
   * Private method to build the auth headers from the app settings user.
   * @return {{Authorization: string, Content-Type: string}}
   * @private
   */
  _getDefaultHeader(): HttpHeaders {
    const headers: HttpHeaders = {}
    if (this.authStore.authToken) {
      headers["Authorization"] = "Bearer " + this.authStore.authToken
    }
    return headers
  }
}
