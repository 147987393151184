import React from "react"
import classNames from "classnames"
import "./TextButton.scss"

export type ITextButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>

export const TextButton: React.FC<ITextButtonProps> = ({
  children,
  className,
  ...props
}: ITextButtonProps) => {
  return (
    <button className={classNames(`TextButton`, className)} {...props}>
      {children}
    </button>
  )
}
