import { $Keys } from "utility-types"

const HttpRequestTypes = Object.freeze({
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
} as const)

export type HttpRequestType = $Keys<typeof HttpRequestTypes>

export default HttpRequestTypes
