import Keycloak from "keycloak-js"
import { action, computed, makeObservable, observable } from "mobx"
import JwtTokenUtils from "../../utils/JwtTokenUtils"
import { RequestStatistics } from "../api/RequestStatistics"

interface ZsbCockpitAuthToken {
  resource_access?: {
    [key: string]: { roles: Array<string> }
  }
  orgunit_access?: Array<{
    orgId: string
    accessType?: string[]
  }>
  preferred_username?: string
  username?: string
}

interface KeycloakConfig {
  authServerUrl: string
  realm: string
  clientId: string
}

interface UserInfo {
  username?: string
  orgUnitAccess?: string[]
}

export default class AuthStore {
  private keycloak?: Keycloak.KeycloakInstance

  @observable authToken?: string
  @observable apiStatusOk = false
  @observable apiIsLoading = false
  @observable currentPermission = ""

  @computed
  get isLoggedIn() {
    return !!this.authToken
  }

  @computed.struct get tokenPayload(): ZsbCockpitAuthToken | undefined {
    if (!this.authToken) {
      return undefined
    }
    return JwtTokenUtils.parseJwt(this.authToken)
  }

  @computed.struct get userInfo(): UserInfo | undefined {
    const tokenPayload = this.tokenPayload
    if (!tokenPayload) return undefined
    const orgIdList = (tokenPayload.orgunit_access || []).map((item) => {
      return item.orgId
    })
    return {
      username: tokenPayload.preferred_username,
      orgUnitAccess: orgIdList,
    }
  }

  @action loggedIn(userInfo: UserInfo, token: string) {
    this.authToken = token
    window.localStorage.setItem("common/auth/authToken", token)
  }

  @action logout() {
    this.authToken = undefined
    window.localStorage.removeItem("common/auth/authToken")
    if (this.keycloak) {
      this.keycloak.logout()
    }
  }

  @action updateToken(token: string) {
    this.authToken = token
    window.localStorage.setItem("common/auth/authToken", token)
  }

  @action setApiStatusOk(yesno: boolean) {
    this.apiStatusOk = yesno
  }

  @action setIsLoading(yesno: boolean) {
    this.apiIsLoading = yesno
  }

  startTokenRefreshing() {
    window.setInterval(this.refreshToken.bind(this), 5000)
  }

  refreshToken() {
    if (!this.keycloak) {
      return
    }
    const keycloak = this.keycloak
    if (!keycloak.token) {
      return
    }
    keycloak.updateToken(30).then(
      (refreshed) => {
        if (refreshed) {
          this.updateToken(keycloak.token || "")
          console.info("AuthService", "Token was refreshed successfully")
        }
      },
      () => {
        console.warn(
          "AuthService",
          "Failed to refresh the token, or the session has expired"
        )
        this.logout()
      }
    )
  }

  onUnauthorized() {
    this.logout()
  }

  onSuccessfulRequest() {
    this.setApiStatusOk(true)
    this.setIsLoading(false)
  }

  onUnsuccessfulRequest(stats: RequestStatistics) {
    if (!stats.responseCode || stats.responseCode >= 400) {
      this.setApiStatusOk(false)
    }
    this.setIsLoading(false)
  }

  onStartedRequest() {
    this.setIsLoading(true)
  }

  constructor(keycloakConfig: KeycloakConfig) {
    const token = window.localStorage.getItem("common/auth/authToken")
    if (token) {
      this.authToken = token
    }
    if (keycloakConfig) {
      this.keycloak = Keycloak({
        url: keycloakConfig.authServerUrl,
        realm: keycloakConfig.realm,
        clientId: keycloakConfig.clientId,
      })
    }
    makeObservable(this)
  }

  public init() {
    const keycloak = this.keycloak
    if (keycloak) {
      keycloak
        .init({
          onLoad: "login-required",
          checkLoginIframe: false,
        })
        .then((authenticated) => {
          if (authenticated && keycloak.token) this.updateToken(keycloak.token)
        })
      this.startTokenRefreshing()
    }
  }
}
