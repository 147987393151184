import ClipboardJS from "clipboard"
import { observable } from "mobx"
import { observer } from "mobx-react"
import moment from "moment"
import React from "react"
import {
  Button,
  Card,
  CardBody,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import "./UserInfoModal.scss"

class ClipboardButton extends React.Component<{ content: string }> {
  componentDidMount() {
    new ClipboardJS(".clipboard-btn")
  }

  render() {
    return (
      <Button
        color="primary"
        className="clipboard-btn"
        data-clipboard-text={this.props.content}
      >
        In die Zwischenablage kopieren
      </Button>
    )
  }
}

@observer
class UserInfoModal extends React.Component {
  @observable detailsVisible = false

  static contextType = ServiceManagerContext

  onClose() {
    if (this.detailsVisible) {
      this.toggleDetails()
    }
    this.context?.userNotificationStore.closeInfoModal()
  }

  retry() {
    let skipDelay = false
    if (this.context?.userNotificationStore.infoModal?.modalAction) {
      this.context?.userNotificationStore.infoModal?.modalAction.callback()
      skipDelay = true
    }
    this.context?.userNotificationStore.closeInfoModal(skipDelay)
  }

  toggleDetails() {
    this.detailsVisible = !this.detailsVisible
  }

  render() {
    const detailsVisible = this.detailsVisible
    const userNotificationState = this.context?.userNotificationStore.infoModal

    if (!userNotificationState) return null

    const modalTitle = userNotificationState.modalTitle
    const modalMessage = userNotificationState.modalMessage
    const modalData = userNotificationState.modalData
    const clipboardContent = `=== ZSB-Cockpit Hinweismeldung ('${moment().format()}') ===\n${modalTitle}\n${modalMessage}\n${modalData}`

    return (
      <div>
        <Modal isOpen={true} className="ErrorModal">
          <ModalHeader toggle={this.onClose.bind(this)}>
            {userNotificationState.modalTitle}
          </ModalHeader>

          <ModalBody>
            <p>{userNotificationState.modalMessage}</p>
            <Collapse isOpen={detailsVisible}>
              <Card>
                <CardBody>
                  <pre>{modalData}</pre>
                </CardBody>
                <ClipboardButton content={clipboardContent} />
              </Card>
            </Collapse>
          </ModalBody>

          <ModalFooter>
            {!!userNotificationState.modalData && (
              <Button color="primary" onClick={this.toggleDetails.bind(this)}>
                Details
              </Button>
            )}
            {!!userNotificationState.modalAction && (
              <Button color="primary" onClick={this.retry.bind(this)}>
                {userNotificationState.modalAction.name}
              </Button>
            )}
            <Button color="secondary" onClick={this.onClose.bind(this)}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default UserInfoModal
