import { observer } from "mobx-react"
import React from "react"
import { UncontrolledAlert } from "reactstrap"
import { ServiceManagerContext } from "../../../../AppServiceManager"
import "./UserNotificationAlert.scss"
import { ToastMessage } from "../../model/UserNotificationStore"

@observer
class UserNotificationAlert extends React.Component {
  static contextType = ServiceManagerContext

  onClose(id: string) {
    this.context?.userNotificationStore.removeToastMessage(id)
  }

  render() {
    return (
      <div className="UserNotificationAlerts">
        <div>
          {this.context?.userNotificationStore.toastMessages.map(
            (message: ToastMessage) => (
              <UncontrolledAlert
                key={message.id}
                color={message.color}
                isOpen={true}
                toggle={this.onClose.bind(this, message.id)}
                fade={false}
              >
                {message.message && (
                  <div>
                    <strong>{message.title}</strong>
                    <br />
                    {message.message}
                  </div>
                )}
                {!message.message && <div>{message.title}</div>}
              </UncontrolledAlert>
            )
          )}
        </div>
      </div>
    )
  }
}

export default UserNotificationAlert
