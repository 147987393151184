import { createContext } from "react"
import { makeAutoObservable, runInAction } from "mobx"
import ApiService from "../api/ApiService"
import { ServiceManagerInstance } from "../../../AppServiceManager"
import _ from "lodash"
import HttpRequestTypes from "../api/HttpRequestType"
import { generateDevPhotos } from "../../utils/photoGenerator"

export enum PhotoStatus {
  Accepted = "accepted",
  Declined = "declined",
  Pending = "pending",
}

export interface IPhoto {
  id: string
  status: PhotoStatus
  selected: boolean
  url: string
  content: ApiContent
}

export type ApiContent = {
  id: string
  orgId: number
  sendungsBarcodes: Array<string>
  contentType: string
  tourDatum: string // e.g. "2020-10-22"
  tourNummer: number
  stoppNummer: number
  traceId: string
  loeschAnfrage: null
  pruefAnfrageFlagEinschalten: {
    traceId: null
    anfragesteller: string
    grund: string
    zeitstempelErstellung: string // e.g. "2020-10-29T13:06:39.902Z"
  }
  pruefAnfrageFlagAusschalten: null
  zeitstempelErstellung: string
  link: {
    rel: "self"
    href: string
  }
}

interface IApiGetSignatureResultBody {
  content: ApiContent[]
}

const isInDev = () => process.env.NODE_ENV === "development"

export class PhotoStore {
  photos: IPhoto[] = []
  gallerySize = 250

  constructor(private readonly apiService: ApiService) {
    makeAutoObservable(this)
  }

  get remainingPhotos() {
    return this.photos.filter((ph) => ph.status === PhotoStatus.Pending).length
  }

  _setStatusOnPhoto(photo: IPhoto, status: PhotoStatus) {
    this._getPhoto(photo).status = status
  }

  _getPhoto(photo: IPhoto): IPhoto {
    const index = this.photos.findIndex((p) => p.id === photo.id)
    if (index < 0) {
      throw new Error("Unknown photo with id: " + photo.id)
    }
    return this.photos[index]
  }

  acceptPhoto(photo: IPhoto) {
    this._setStatusOnPhoto(photo, PhotoStatus.Accepted)
  }

  setGalleryImageSize(size: number) {
    this.gallerySize = size
  }

  declinePhoto(photo: IPhoto) {
    this._setStatusOnPhoto(photo, PhotoStatus.Declined)
  }

  selectPhoto(photo: IPhoto) {
    this._getPhoto(photo).selected = true
  }

  selectAll() {
    this.photos.forEach((photo) => (photo.selected = true))
  }

  deselectAll() {
    this.photos.forEach((photo) => (photo.selected = false))
  }

  get allSelected() {
    return this.photos.every((photo) => photo.selected)
  }

  get allDeselected() {
    return this.photos.every((photo) => !photo.selected)
  }

  deselectPhoto(photo: IPhoto) {
    this._getPhoto(photo).selected = false
  }

  get selectedPhotos() {
    return this.photos.filter((p) => p.selected)
  }

  get selectedPhotoCount() {
    return this.selectedPhotos.length
  }

  _removePhotosById(...ids: string[]) {
    const deleted = this.photos.filter((p) => ids.includes(p.id))
    this.photos = _.difference(this.photos, deleted)
    return deleted
  }

  _removePhotosByResponse(
    photoIdArray: ApiContent[],
    responseStatusArray: number[]
  ) {
    if (!Array.isArray(responseStatusArray)) {
      throw new Error("Response does not contain an array")
    }

    if (photoIdArray.length !== responseStatusArray.length) {
      throw new Error(
        "Response array length does not match requested element length"
      )
    }

    const successfulDeletedImages = photoIdArray
      .filter((photoId, idx) => {
        return responseStatusArray[idx] === 200
      })
      .map((c) => c.id)
    return this._removePhotosById(...successfulDeletedImages)
  }

  async deleteSelectedPhotos(reason: string): Promise<IPhoto[]> {
    const contentsToDelete = this.selectedPhotos.map((photo) => photo.content)

    const responseStatusArray: number[] = await (
      await this.apiService.request(
        `signatures/delete?reason=${reason}`,
        HttpRequestTypes.POST,
        { "Content-Type": "application/json" },
        contentsToDelete
      )
    ).json()

    return this._removePhotosByResponse(contentsToDelete, responseStatusArray)
  }

  async retainSelectedPhotos(reason: string) {
    const contentToRetain = this.selectedPhotos.map((photo) => photo.content)

    const responseStatusArray: number[] = await (
      await this.apiService.request(
        `signatures/unmark?reason=${reason}`,
        HttpRequestTypes.POST,
        { "Content-Type": "application/json" },
        contentToRetain
      )
    ).json()

    return this._removePhotosByResponse(contentToRetain, responseStatusArray)
  }

  async fetchPhoto(url: string): Promise<Blob> {
    const request = await this.apiService.request(url)
    return request.blob()
  }

  async fetchPhotos() {
    const data = await this.apiService.request("signatures")
    await runInAction(async () => {
      const responseJson: IApiGetSignatureResultBody = await data.json()
      this.photos = responseJson.content.map((c) => ({
        id: c.id,
        url: this.apiService.getApiBase() + "signatures/" + c.id,
        content: c,
        status: PhotoStatus.Pending,
        selected: false,
      }))
      if (isInDev()) {
        this.photos = [...this.photos, ...generateDevPhotos(50)]
      }
    })
  }
}

export default createContext(new PhotoStore(ServiceManagerInstance.apiService))
