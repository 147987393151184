import Local from "./local"
import Production from "./production"
import Staging from "./staging"
import Development from "./development"

export type EnvironmentConfiguration = typeof Local

export default function (): EnvironmentConfiguration {
  if (location.href.indexOf(Production.urlMatch) >= 0) return Production
  if (location.href.indexOf(Staging.urlMatch) >= 0) return Staging
  if (location.href.indexOf(Development.urlMatch) >= 0) return Development
  if (window.localStorage.getItem("dev/environment") === "Local") return Local
  return Staging
}
