import { observable } from "mobx"
import { v1 as uuidV1 } from "uuid"

export enum ToastMessageColor {
  Primary = "primary",
  Danger = "danger",
}

export interface ToastMessage {
  id: string
  uniqueKey: string | null | undefined
  title: string
  message: string | null | undefined
  color: ToastMessageColor
}

export interface ModalAction {
  name: string
  callback: () => void
}

interface InfoModal {
  modalTitle: string
  modalMessage: string
  modalData?: string
  modalAction?: ModalAction
}

const restartAction = {
  name: "App Neustarten",
  callback: () => {
    return window.location.reload()
  },
}

export default class UserNotificationStore {
  @observable toastMessages: ToastMessage[] = []

  @observable infoModal?: InfoModal
  @observable isDelayed = false

  showInfoModal(
    title: string,
    message: string,
    data?: unknown,
    action?: ModalAction
  ): void {
    if (this.isDelayed) return
    this.infoModal = {
      modalTitle: title,
      modalMessage: message,
      modalData:
        typeof data === "string" ? data : JSON.stringify(data, undefined, 2),
      modalAction: action,
    }
  }

  closeInfoModal(skipDelay = false): void {
    this.infoModal = undefined
    if (!skipDelay) {
      this.startModalDelay()
      setTimeout(() => this.stopModalDelay(), 5 * 1000)
    }
  }

  startModalDelay(): void {
    this.isDelayed = true
  }

  stopModalDelay(): void {
    this.isDelayed = false
  }

  addToastMessage(
    title: string,
    message: string | undefined,
    color: ToastMessageColor = ToastMessageColor.Primary,
    uniqueKey?: string
  ) {
    const id = uuidV1()
    if (
      uniqueKey &&
      this.toastMessages.some((tm) => tm.uniqueKey === uniqueKey)
    ) {
      return
    }
    this.toastMessages.push({
      id,
      uniqueKey,
      title,
      message,
      color,
    })
    setTimeout(() => this.removeToastMessage(id), 10 * 1000)
  }

  removeToastMessage(id: string) {
    this.toastMessages = this.toastMessages.filter(
      (m: ToastMessage) => m.id !== id
    )
  }

  handleErrorBoundary(title: string, message: string, details: unknown) {
    this.showInfoModal(title, message, details, restartAction)
  }

  async handleApiResponseDefaultErrors(
    module: string,
    responseOrError: Response | Error,
    title: string,
    displayPriority: "blocking" | "info" | "log_only",
    options?: {
      module?: string
      callback?: () => void
    }
  ) {
    const response = responseOrError as Response
    const action = options?.callback
      ? {
          callback: options.callback,
          name: "Nochmal Versuchen",
        }
      : undefined
    console.log(response)
    if (!response.status) {
      const message =
        "Die Verbindung zum Server ist fehlgeschlagen. Bitte überprüfen Sie die Internetverbindung."
      switch (displayPriority) {
        case "blocking":
          this.showInfoModal(title, message, null, action)
          break
        case "info":
          this.addToastMessage(
            title,
            message,
            ToastMessageColor.Danger,
            "fetchRejection"
          )
          break
        default:
          this.showInfoModal(title, message, "fetchRejection")
          break
      }
    } else {
      const { ok, redirected, status, statusText, type, url } = response
      let details: Record<string, unknown> = {}
      if (response.text) {
        details = {
          ok,
          redirected,
          status,
          statusText,
          type,
          url,
        }
        try {
          details.body = await response.text()
        } catch (error) {
          details.errorMessage = error.message
          details.stack = error.stack
        }
      }

      if (status === 401) {
        console.warn(`${module} unauthorizedError`, title)
      } else if (status === 404) {
        const message = "Das Element existiert nicht."
        this.showInfoModal(title, message, details)
      } else if (status >= 400 && status < 500) {
        const message =
          "Die übertragenen Daten wurden vom Server nicht akzeptiert. Bitte kontaktieren Sie Ihren Ansprechpartner."
        this.showInfoModal(title, message, details, restartAction)
      } else if (status >= 500 && status < 600) {
        const message =
          "Es ist ein Fehler bei der Datenverarbeitung aufgetreten. Falls der Fehler länger besteht kontaktieren Sie bitte Ihren Ansprechpartner."
        this.showInfoModal(title, message, details, action)
      } else {
        const message =
          "Es ist ein unbekannter Fehler aufgetreten. Bitte kontaktieren Sie Ihren Ansprechpartner."
        this.showInfoModal(title, message, details, restartAction)
      }
    }
  }
}
