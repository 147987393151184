import React from "react"
import "./NavigationBar.scss"
import logo from "../../../../logo.svg"
import { ServiceManagerInstance } from "../../../../AppServiceManager"
import { TextButton } from "../TextButton/TextButton"
import { UncontrolledTooltip } from "reactstrap"

const Logo = () => {
  return (
    <div className={`Logo`}>
      <div>
        <img src={logo} alt={""} />
      </div>
      <h1 className={`Logo-Appname`}>Photo Control</h1>
    </div>
  )
}

const NavigationBar = () => {
  const formatName = (name: string) => {
    if (name.length < 4) {
      return name
    }
    return (
      name.substr(0, 1).toLocaleUpperCase() +
      name.substr(1, name.length - 4) +
      name.substr(name.length - 3, 1).toLocaleUpperCase() +
      name.substr(name.length - 2, name.length)
    )
  }

  return (
    <div className={`navigationBar`}>
      <div className={`inner`}>
        <Logo />
        <TextButton
          id={"logout"}
          className={`navigationBar-logout`}
          onClick={() => {
            ServiceManagerInstance.authStore.logout()
          }}
        >
          Abmelden
          <UncontrolledTooltip
            placement="bottom-end"
            target="logout"
            delay={{ show: 500, hide: 0 }}
            style={{
              backgroundColor: "#000",
              borderRadius: "4px",
              textAlign: "left",
              fontSize: "12px",
              lineHeight: "1.4",
              padding: "8px 10px",
            }}
          >
            {formatName(
              ServiceManagerInstance.authStore.tokenPayload
                ?.preferred_username || ""
            ) + ` ausloggen`}
          </UncontrolledTooltip>
        </TextButton>
      </div>
    </div>
  )
}

export default NavigationBar
