export default class RequestUtil {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static appendParametersToUrl(url: string, params: any): string {
    if (Object.keys(params).length === 0) {
      return url
    }

    let prefix = "?"
    const builtUrl = url

    if (url.includes("?")) {
      prefix = "&"
    }

    const paramsStr = Object.keys(params)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          "" + params[key]
        )}`
      })
      .join("&")

    return builtUrl + prefix + paramsStr
  }

  static parseUrlParameters(
    url: string
  ): {
    [key: string]: string
  } {
    if (!url.includes("?")) {
      return {}
    }
    const parts = url.split("?")
    const parameterPart = parts[1]
    const params = parameterPart.split("&")
    const urlParams: { [key: string]: string } = {}
    for (const param of params) {
      const keyVal = param.split("=")
      urlParams[keyVal[0]] = keyVal[1]
    }
    return urlParams
  }
}
