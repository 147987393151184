import { IPhoto, PhotoStatus } from "../app/model/PhotoStore"
import { v4 } from "uuid"

export const generateDevPhotos = (count: number): IPhoto[] => {
  const result = []
  for (let i = 0; i < count; i++) {
    result.push(generatePhotosForDevelopment())
  }
  return result
}

const generatePhotosForDevelopment = () => {
  return {
    id: v4(),
    url: "https://picsum.photos/576/768",
    status: PhotoStatus.Pending,
    selected: false,
    content: {},
  } as IPhoto
}
