export default function timeoutPromise<T>(
  ms: number,
  promise: Promise<T>
): Promise<T> {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject(new Error(`promise timed out after ${ms} ms.`))
    }, ms)
    promise.then(resolve, reject)
  })
}
