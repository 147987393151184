import { hot } from "react-hot-loader"
import { observer } from "mobx-react"
import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Container } from "reactstrap"
import "./App.scss"
import {
  ServiceManagerContext,
  ServiceManagerInstance,
} from "./AppServiceManager"
import UserInfoModal from "./modules/app/components/UserInfoModal/UserInfoModal"
import UserNotificationAlert from "./modules/app/components/UserNotificationAlert/UserNotificationAlert"
import NavigationBar from "./modules/app/components/NavigationBar/NavigationBar"
import PhotoSelection from "./modules/app/components/PhotoSelection/PhotoSelection"

@observer
class App extends React.Component {
  render() {
    if (!ServiceManagerInstance.authStore.isLoggedIn) return null

    return (
      <ServiceManagerContext.Provider value={ServiceManagerInstance}>
        <div className="App">
          <ErrorBoundary
            fallbackRender={() => <div></div>}
            onError={(e) =>
              ServiceManagerInstance.userNotificationStore.handleErrorBoundary(
                e.name,
                e.message,
                e.stack
              )
            }
          >
            <NavigationBar />
            <UserNotificationAlert />
            <div className="Main">
              <Container fluid>
                <PhotoSelection />
              </Container>
            </div>
          </ErrorBoundary>
          <UserInfoModal />
        </div>
      </ServiceManagerContext.Provider>
    )
  }
}

export default hot(module)(App)
