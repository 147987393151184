import React, { CSSProperties } from "react"
import "./Button.scss"
import classNames from "classnames"
import { IconType } from "react-icons"

export enum ButtonColor {
  Success = "success",
  Danger = "danger",
  Primary = "primary",
}

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string
  icon?: IconType
  color: ButtonColor
  style?: CSSProperties
  className?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  disabled?: boolean
}

const Button = ({
  icon,
  text,
  color,
  style,
  className,
  onClick,
  disabled = false,
  ...props
}: IButtonProps) => {
  const TheIcon = icon

  return (
    <button
      {...props}
      disabled={disabled}
      onClick={(e) => onClick && onClick(e)}
      style={style}
      className={classNames("Button", { [color]: true }, className)}
    >
      {TheIcon && (
        <TheIcon style={{ marginLeft: "-20px", marginRight: "20px" }} />
      )}
      {text}
    </button>
  )
}

export default Button
